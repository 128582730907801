<template>
  <div>
    <Loading class="overlay" :active.sync="visible"></Loading>
    <div v-if="activePost || activeForum || activeComment" class="content">
      <div class="badge">
        <span>Denied {{ type }}</span>
      </div>
      <div v-if="type === 'post' || type === 'comment'" class="parent-forum">
        <span>Posted in:</span>
        <span class="blue" @click="goToForum">{{ removedEntity.forumName }}</span>
        <!-- todo: get forum name to show here instead of ID -->
      </div>
      <div class="original-post">
        <div class="top-row" @click="opened = !opened">
          <div class="header">Show Original Post</div>
          <Arrow :class="{ opened: opened }" />
        </div>
        <template v-if="opened">
          <div class="meta">
            <div class="author">
              <ProfilePicture
                :twoFourgId="get24gId(userInfo.userID)"
                :userId="userInfo.userID"
                :border="false"
                class="profilePicture"
              />
              <div @click="$router.push(`/user/${removedEntity.author}`)" class="userName">
                {{ authorName(userInfo.userID) }}
              </div>
            </div>
            <div class="timestamp">{{ removedEntity.createdAt | moment('MM/DD/YYYY LT') }}</div>
          </div>
          <div
            class="text"
            v-html="addAnchorTags(removedEntity.content) || addAnchorTags(removedEntity.description)"
          ></div>
        </template>
        <div class="reason">
          <span class="red">Reason for Removal: </span>
          <span>{{ removedEntity.reason }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { mapGetters } from 'vuex';
import { addAnchorTags } from '@/utils/helpers';
import ProfilePicture from '@/components/user/ProfilePicture.vue';
import Arrow from '@/assets/icons/arrow.svg';

export default {
  name: 'removal-reason',
  components: {
    Loading,
    ProfilePicture,
    Arrow,
  },
  data() {
    return {
      visible: false,
      opened: false,
    };
  },
  computed: {
    ...mapGetters(['activeComment', 'activePost', 'activeForum', 'users', 'userInfo']),
    type() {
      return this.$route.params.type;
    },
    entityId() {
      return this.$route.params.entityId;
    },
    removedEntity() {
      if (this.activeForum) return this.activeForum;
      if (this.activePost) return this.activePost;
      if (this.activeComment) return this.activeComment;
      return null;
    },
  },
  methods: {
    get24gId(userId) {
      if (this.users) {
        const x = this.users.findIndex((user) => user.id === userId);
        if (x > -1) {
          return this.users[x]['24gId'];
        }
      }
      return '';
    },
    authorName(id) {
      if (this.users) {
        const x = this.users.findIndex((user) => user.id === id);
        if (x > -1) {
          return `${this.users[x].firstName} ${this.users[x].lastName}`;
        }
      }
      return 'user';
    },
    addAnchorTags(html) {
      return addAnchorTags(html, this.users);
    },
    goToForum() {
      if (this.removedEntity && this.removedEntity.private === 0) {
        this.$router.push(`/forum/${this.removedEntity.forumId}`);
      } else if (this.removedEntity && this.removedEntity.private === 1) {
        this.$router.push(`/group/${this.removedEntity.forumId}`);
      }
    },
  },
  created() {
    this.visible = true;
    if (this.type && this.type === 'post' && this.entityId) {
      this.$store.dispatch('getPost', this.entityId).finally(() => {
        this.visible = false;
      });
    } else if (this.type && this.type === 'forum' && this.entityId) {
      this.$store.dispatch('getForum', this.entityId).finally(() => {
        this.visible = false;
      });
    } else if (this.type && this.type === 'comment' && this.entityId) {
      this.$store.dispatch('getComment', this.entityId).finally(() => {
        this.visible = false;
      });
    } else {
      this.$router.push('/');
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: $primary-white;
  box-shadow: 0px 2px 6px #00000029;
  padding: 50px 35px;
  position: relative;
  .badge {
    background-color: $danger;
    padding: 5px 10px;
    position: absolute;
    top: 0;
    left: 0;
    span {
      text-transform: capitalize;
      color: $primary-white;
      font-family: $head-font-stack;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
  .parent-forum {
    padding: 25px 0;
    border-bottom: 1px solid $light-grey;
    font-family: $head-font-stack;
    font-weight: 300;
    font-size: 1.4rem;
    .blue {
      font-weight: bold;
      color: $primary-light;
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .original-post {
    .top-row {
      padding: 35px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .header {
        font-family: $head-font-stack;
        font-weight: bold;
        font-size: 1.6rem;
      }
      svg {
        height: 20px;
        transform: rotate(90deg);
        transition: all 0.3s ease-in-out;
        &.opened {
          transform: rotate(-90deg);
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 25px;
      .author {
        font-family: $head-font-stack;
        font-weight: bold;
        font-size: 1.4rem;
        color: $primary-light;
        cursor: pointer;
        display: flex;
        align-items: center;
        .profilePicture {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }
      .timestamp {
        font-family: $head-font-stack;
        font-weight: 300;
        font-size: 1.2rem;
      }
    }
    .text {
      font-family: $text-font-stack;
      font-size: 1.4rem;
      font-weight: 300;
      padding-bottom: 25px;
      border-bottom: 1px solid $light-grey;
    }
    .reason {
      padding-top: 25px;
      font-size: 1.4rem;
      font-weight: 300;
      font-family: $text-font-stack;
      .red {
        font-family: $head-font-stack;
        font-weight: bold;
        color: $danger;
      }
    }
  }
}
</style>
